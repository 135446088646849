.text-header,
.text-heading {
    font-family: "Recoleta";


}

.text-header {
    font-size: pxToEm(28);

    b {
        font-weight: bold;
        font-size: pxToEm(29);
    }
}

.text-heading {
    font-size: pxToEm(24);

    b {
        font-weight: bolder;
        font-size: pxToEm(25);
    }
}

.text-subheading {
    font-size: pxToEm(20);
}

.text-body {
    font-size: pxToEm(14);
    line-height: pxToEm(1);
}

.text-sub {
    font-size: pxToEm(12);
}



.ios {
    input {
        font-size: 16px !important;
    }
}