@tailwind base;
@tailwind components;
@tailwind utilities;


@layer components {

  body {
    @apply font-medium text-xs font-basisGrotesquePro;
  }

  .payment-form {
    @apply lg:relative md:relative md:p-10 px-5 py-5 md:my-4
    w-full md:w-full lg:w-5/6 xl:w-7/12 
    lg:self-center md:self-center
    flex flex-col
    xl:grow-0 lg:grow-0 md:grow-0 grow;
  }

  .btn {
    @apply focus:outline-none py-2 px-12 md:px-24 shadow disabled:opacity-50 disabled:pointer-events-none text-white flex justify-center;
  }
  .btn.back {
    @apply bg-corduroy-500;
  }
  
  .btn.next {
    @apply bg-secondary-500;
  }
  
  .btn.success {
    @apply bg-secondary-500;
  }

 

  #cart-summary {
    @apply bg-secondary-50 rounded;
    @apply px-4 py-6;
    box-shadow: -4px 0px 12px 0px #00000026;
  }

  .cart-cost-item {
    @apply flex justify-between font-light text-sm mb-4;
  }

  .vertification-fields {
    @apply flex flex-col font-light;
  }


  input[type=radio]{
    @apply h-5 w-5 align-middle;
    @apply rounded-full border-corduroy-400 border-solid border-[1px];
    @apply appearance-none;
  }

  input:checked[type=radio] {
    @apply bg-primary-400;
    background-image: url('data:image/svg+xml;charset=utf-8,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -4 8 8"%3E%3Ccircle r="2" fill="%23fff"/%3E%3C/svg%3E')
  }

  hr {
    @apply w-full my-6 mx-0 clear-both basis-full border-t-corduroy-500; 
  }

  header {
    @apply bg-primary-500 w-full py-4 px-4 md:px-16 h-[120px] flex;
  }

  header .logo {
    @apply my-auto;
  }
  footer {
    @apply bg-primary-500 w-full py-4 px-4 md:px-16 mt-auto;
  }

  .PhoneInput,
  .PhoneInput input {
    @apply w-full;
  }
  .phone-input.error {
    @apply border-primary-500;
  }

  .skeleton {
    @apply bg-corduroy-300
  }
  
  .skeleton.image {
    @apply bg-corduroy-300
  }

  .skeleton.image.animation {
    @apply bg-corduroy-300;
    background-position-x: 180%;
    @apply animate-skeleton-loading;
    @apply bg-[length:200%_100%] ;
    @apply bg-gradient-deg-100 from-corduroy-300 from-35% via-corduroy-200 to-65% to-corduroy-300  rounded-xl;

  }

}


.phone-input > .PhoneInputCountry {
    padding-right: 8px;
  }

 .phone-input > input {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-top: unset !important;
    border-right: unset !important;
    border-bottom: unset !important;
    outline: none;
  }
.react-tel-input {
   width:100% !important;
   margin-top:0.25rem;
}
.react-tel-input .form-control {
  width:100% !important;
  height: unset !important;
  padding-left: 0.75rem !important;
  line-height: unset !important;
}
.react-tel-input .flag-dropdown {
  display: none !important;
}

