@use "sass:map";

$colors: (
  primary: (50: #fdf5f7,
    100: #fbebee,
    200: #f6cdd6,
    300: #f1aebd,
    400: #e6728b,
    500: #db3559,
    600: #c53050,
    700: #a42843,
    800: #832035,
    900: #6b1a2c),
  secondary: (50: #f7fdfd,
    100: #f0fafa,
    200: #d9f4f3,
    300: #c2edec,
    400: #94dfdd,
    500: #66d1cf,
    600: #5cbcba,
    700: #4d9d9b,
    800: #3d7d7c,
    900: #326665),
  warning: (50: #fefef8,
    100: #fdfcf0,
    200: #f9f8da,
    300: #f6f4c3,
    400: #efeb96,
    500: #e8e369,
    600: #d1cc5f,
    700: #aeaa4f,
    800: #8b883f,
    900: #726f33),
  success: (50: #f5fbf5,
    100: #ebf7eb,
    200: #cceccd,
    300: #aee0af,
    400: #71c973,
    500: #34b237,
    600: #2fa032,
    700: #278629,
    800: #1f6b21,
    900: #19571b),
  error: (50: #fff3f3,
    100: #fee7e7,
    200: #fdc4c4,
    300: #fba1a1,
    400: #f95a5a,
    500: #f61313,
    600: #dd1111,
    700: #b90e0e,
    800: #940b0b,
    900: #790909),
  gray: (50: #fbfcfc,
    100: #f8f9f9,
    200: #edefef,
    300: #e2e5e5,
    400: #ccd2d2,
    500: #b6bfbf,
    600: #a4acac,
    700: #898f8f,
    800: #6d7373,
    900: #595e5e),
  corduroy: (50: #f6f7f7,
    100: #edf0f0,
    200: #d3d9d9,
    300: #b8c2c2,
    400: #829494,
    500: #4d6666,
    600: #455c5c,
    700: #3a4d4d,
    800: #2e3d3d,
    900: #263232)
);

@mixin colors {

  @each $color,
  $shades in $colors {

    @each $shade,
    $value in $shades {
      --#{"" + $color}-#{$shade}: #{$value};
    }
  }
}