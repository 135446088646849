@tailwind base;
@tailwind components;
@tailwind utilities;


// Import color variables in the root for initialization
@import "./functions";
@import "./abstract";
@import "./globals";

:root {
    @include colors;
}