// Basis Grotesque Pro

@font-face {
    font-family: "Basis Grotesque Pro";
    font-weight: 300;
    src: url(../../fonts/BasisGrotesqueArabicPro-Light.ttf) format("truetype");
}

@font-face {
    font-family: "Basis Grotesque Pro";
    font-weight: 500;
    src: url(../../fonts/BasisGrotesqueArabicPro-Regular.ttf) format("truetype");
}

@font-face {
    font-family: "Basis Grotesque Pro";
    font-weight: 600;
    src: url(../../fonts/BasisGrotesqueArabicPro-Medium.ttf) format("truetype");
}


@font-face {
    font-family: "Basis Grotesque Pro";
    font-weight: 700;
    src: url(../../fonts/BasisGrotesqueArabicPro-Bold.ttf) format("truetype");
}

@font-face {
    font-family: "Basis Grotesque Pro";
    font-weight: 800;
    src: url(../../fonts/BasisGrotesqueArabicPro-Black.ttf) format("truetype");
}


// Recoleta
@font-face {
    font-family: "Recoleta";
    font-weight: 100;
    src: url("../../fonts/Recoleta-Thin.woff2") format("woff2");
}

@font-face {
    font-family: "Recoleta";
    font-weight: 300;
    src: url("../../fonts/Recoleta-Light.woff2") format("woff2");
}

@font-face {
    font-family: "Recoleta";
    font-weight: 500;
    src: url("../../fonts/Recoleta-Regular.woff2") format("woff2");
}

@font-face {
    font-family: "Recoleta";
    font-weight: 600;
    src: url("../../fonts/Recoleta-Medium.woff2") format("woff2");
}

@font-face {
    font-family: "Recoleta";
    font-weight: 700;
    src: url("../../fonts/Recoleta-Bold.woff2") format("woff2");
}

@font-face {
    font-family: "Recoleta";
    font-weight: 800;
    src: url("../../fonts/Recoleta-Black.woff2") format("woff2");
}