hr {
	margin: 24px 0;
}

.circles-background--header {
	background-image: url(../../images/circles.svg);
	background-repeat: no-repeat;
	background-size: 1000px;
	background-position: right -550px top -650px;
}

@media only screen and (max-width: 600px) {
	.circles-background--header {
		background-size: 800px;
		background-position: right -475px top -575px;
	}
}