.input {
	$padding-x: 14px;
	$padding-y: 13px;
	--text-color: black;
	--error-msg-height: 24px;
	margin-bottom: 8px;
	padding-bottom: 24px; // space for error message
	position: relative;

	.input-wrapper {
		position: relative;
		border: 0;
		background-color: var(--secondary-50);
		border-bottom: 1px solid;
		border-radius: 8px 8px 0 0;
		display: flex;
		transition: all .2s ease-in-out;
		padding: $padding-y $padding-y;
		transition: all .5s ease;
		box-sizing: border-box;

		.label {
			color: rgba($color: #000000, $alpha: 0.66)
		}

		&:has(input:not(:placeholder-shown)),
		&:focus-within {
			border: 1px solid var(--secondary-500);
			background-color: white;

			.label--floating {
				top: 0;
				scale: .8;
				opacity: 1;
				transform: translateY(-$padding-y);
				padding: 2px 6px;
				font-weight: 700;


				// TODO:, causes a white line to appear over notes field
				&::after {
					background-color: white;
				}

			}
		}

		&.valid {
			--text-color: var(--secondary-500);
			border-color: var(--text-color) !important;

			.label--floating {
				color: var(--text-color);
			}
		}

		&.invalid {
			--text-color: var(--error-500);
			border-color: var(--text-color) !important;

			.label--floating {
				color: var(--text-color);
			}

			
		}

		&.disabled {
			background: #dddddd00 !important;
			border-color: #dddddd !important;

			.label--floating {
				color: #dddddd;
			}
		}

		&:has(textarea) {
			border-radius: 8px;
			border: 1px solid transparent;
		}
	}

	&-error {
		padding: 8px 0;
		color: var(--error-500);
		font-size: 0.9em;
		line-height: 0.9em;
		height: 24px;
		position: absolute;
		bottom: 0;
		left: 0;
		transform: translateY(0);
		opacity: 1;
		animation: topDown .6s ease-out;
	}

	input {
		background: transparent;
		color: black;
		width: 100%;
		border: 0;
		outline: 0;
	}

	textarea {
		background: transparent;
		color: black;
		width: 100%;
		border: 0;
		outline: 0;
		height: 150px;
		resize: none;
	}

	.react-select__control {
		background: transparent;
		border: unset;
		padding: unset;
		margin: unset;
		min-height: unset;
		width: 100%;
	}

	.react-select__control--is-disabled {
		.react-select__indicators {
			color: #dddddd;
		}
	}

	.react-select__menu {
		background: white;
		z-index: 3;
		box-shadow: 0 0 0 1px hsla(0, 0%, 0%, 0.1), 0 4px 11px hsla(0, 0%, 0%, 0.1);
		border-radius: 4px;
	}

	.react-select__menu-list {
		max-height: 300px;
		overflow-y: auto;
		position: relative;
		padding-top: 4px;
		padding-bottom: 4px;
	}

	.react-select__option {
		cursor: default;
		display: block;
		width: 100%;
		padding: 8px 12px;
		z-index: 3;
	}

	.react-select__option:hover {
		background-color: var(--secondary-50);
	}

	.react-select__option:active {
		background-color: var(--secondary-500);
	}



	.label {
		pointer-events: none;
		transition: all .5s ease;
		color: black;

		&--floating {
			position: absolute;
			top: $padding-y;
			bottom: 0;
			margin: auto;
			z-index: 2;
			opacity: .7;
			transform-origin: left top;
			left:3rem;

			&::after {
				content: "";
				top: 25%;
				left: 0;
				z-index: -1;
				height: 5px;
				position: absolute;
				width: 100%;
			}

		}
	}

}